import React from "react"
import _ from "underscore"

// import Layout from "../components/layout"
import Seo from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"

const NotFoundPage = () => {
  const thepages = useStaticQuery(graphql`
    query GetAllPages {
      allMarkdownRemark(filter: {frontmatter: {draft: {ne: true}}}) {
        edges {
          node {
            id
            fields {
              slug
              table
            }
            frontmatter {
              title
              type
            }
          }
        }
      }
      pages:  allSitePage(filter: {path: {glob: "/*/"}}) {
        edges {
          node {
            id
            path
          }
        }
      }
    }
  `)
  let groups = _.groupBy(thepages.allMarkdownRemark.edges, function(e){ return e.node.fields.table; })
  let sortedGroup = {}
  for (var group in groups) {
    if (groups.hasOwnProperty(group)) {
      sortedGroup[group] = _.groupBy(groups[group], function(e){ return e.node.frontmatter.type; })
    }
  }
  const spreadsheet = true;
  const topPages = thepages.pages.edges.map((e,i) => <div key={i}><a href={e.node.path}>{e.node.path}</a></div>)
  const printPages = (tables) => {
    var elements = []
    var tableCount = 0;
    for (var table in tables) {
      var types = [];
      var typeCount = 0;
      if (tables.hasOwnProperty(table)) {
        for (var pageType in tables[table]) {
          if (tables[table].hasOwnProperty(pageType)) {
            var links = tables[table][pageType].sort((a,b)=> a.node.frontmatter.title > b.node.frontmatter.title ? 1 : -1).map((link,i) =>
              <div key={++typeCount}><a  style={{fontSize: "1em"}} className='not-found-link'
              href={"/"+link.node.fields.table+link.node.fields.slug}>{link.node.frontmatter.title}</a></div>)
          }
          types = types.concat(links)
          // types.push(<div key={++typeCount}className="not-found-type"><h3>{pageType}</h3><div className='not-found-links'>{links}</div></div>)
        }
      }
      elements.push(<div key={++tableCount} className="not-found-table"><h2>{table}</h2><div className="not-found-types">{types}</div></div>)
    }
    return elements
  }
  return (
    <div id="not-found-page">
      <Seo title="404: Not found" />
      <h2 key={1209}>Top level pages:</h2>
      {topPages}
      <h2>All tables:</h2>
      {printPages(sortedGroup)}
      <div key={100} className="not-found-table">
        <h2>Turn checklist</h2>
        <a style={{fontSize: "1em"}} className='not-found-link'
        href={"/mathmusic/lectiones-elementares-mathematice/"}>lectiones elementares mathematice</a>
        <a style={{fontSize: "1em"}} className='not-found-link'
        href={"/vangogh/vangogh-letter-1/"}>vangogh-letter-1</a>
        <a style={{fontSize: "1em"}} className='not-found-link'
        href={"/vangogh/vangogh-letter-2/"}>vangogh-letter-2</a>
        <a style={{fontSize: "1em"}} className='not-found-link'
        href={"/vangogh/vangogh-letter-3/"}>vangogh-letter-3</a>
        <a style={{fontSize: "1em"}} className='not-found-link'
        href={"/death/untitled-personal-greeting-cards/"}>untitled-personal-greeting-cards</a>
        <a style={{fontSize: "1em"}} className='not-found-link'
        href={"/growth/optical-design-in-motion/"}>optical-design-in-motion</a>
        <a style={{fontSize: "1em"}} className='not-found-link'
        href={"/growth/cats/"}>cats</a>
        <a style={{fontSize: "1em"}} className='not-found-link'
        href={"/mathmusic/smart-notebook/"}>smart-notebook</a>
        <a style={{fontSize: "1em"}} className='not-found-link'
        href={"/growth/birds/"}>birds</a>
      </div>
    </div>
  )
}



export default NotFoundPage
